const env = require('./env.js');

// For POC we only support Chrome 98+
const isBrowserSupported = () => env.isChrome && env.version >= 98;

// SPC is only available if defined by sessionInfo or forced by flag
// and browser supports it.
module.exports = (sessionInfo, enableSinglePeerConnection) => {
  // We disable SPC by default.
  let isSessionEnabled = false;
  // We override sessionInfo with force flag.
  if (typeof enableSinglePeerConnection === 'boolean') {
    isSessionEnabled = enableSinglePeerConnection;
  } else {
    // Otherwise we use spc flag from GSI.
    isSessionEnabled = sessionInfo && sessionInfo.spc;
  }
  return isSessionEnabled && isBrowserSupported();
};
