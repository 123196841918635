/* eslint-disable no-underscore-dangle */

const PeerConnectionDefault = require('./peer_connection')();

class SinglePeerConnectionController {
  _firstSinglepeerConnection;
  _peerConnection;
  _spcToAdd;
  _singlePeerConnections = {};
  _session;
  _tracks = [];

  constructor(session, deps = {}) {
    this._session = session;
    this.PeerConnection = deps.PeerConnection || PeerConnectionDefault;
  }

  // Singleton to make sure we are using only one PC when SPC. If SPC, we will add Subscriber
  // specific options to handle analytics and negotiation per Subscriber. This will take care
  // to send the answer back to Rumor by its respective Subcriber and not multiple answers.
  // It will instantiate a new regular PC for all other cases.
  getPeerConnection(opt, spc) {
    this._spcToAdd = spc;
    if (this._peerConnection) {
      this._peerConnection.addOptions(opt);
    } else {
      this._firstSinglePeerConnection = spc._id;
      this._peerConnection = new this.PeerConnection(opt);
      // override the trackAdded listener so we can route the tracks to the proper subscriber.
      this._peerConnection.on('trackAdded', (stream) => {
        const { track } = stream;
        if (this._tracks.includes(track.id)) {
          return;
        }
        // We store all tracks per Subscriber.
        this._singlePeerConnections[this._spcToAdd._id].push(track);
        // Also store all tracks per SinglePeerConnection
        this._tracks.push(track.id);
        this._spcToAdd._onTrackAdded(stream);
      });
    }

    this._singlePeerConnections[spc._id] = [];

    return this._peerConnection;
  }

  removeSinglePeerConnection(spcId) {
    if (this._firstSinglePeerConnection === spcId) {
      this._peerConnection.iceRestart();
    }
    delete this._singlePeerConnections[spcId];
    if (!Object.keys(this._singlePeerConnections).length) {
      this._peerConnection.disconnect();
      this._peerConnection = null;
    }
  }

  removeTrack(track, spcId) {
    let trackIndex = this._tracks.indexOf(track.id);
    if (trackIndex > -1) {
      this._tracks.splice(trackIndex, 1);
    }
    trackIndex = this._singlePeerConnections[spcId]?.indexOf(track);
    if (trackIndex > -1) {
      this._singlePeerConnections[spcId].splice(trackIndex, 1);
    }
  }

  destroy() {
    this._spcToAdd = null;
    this._singlePeerConnections = null;
    this._session = null;
    this._tracks = null;
    if (this._peerConnection) {
      this._peerConnection.disconnect();
      this._peerConnection = null;
    }
  }
}

module.exports = SinglePeerConnectionController;
